/* background settings */
#root {
  position: relative;
  height: 100vh;
}

#root::before {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-image: url("/public/background.png");
  background-size: cover;
  background-repeat: no-repeat;
  filter: blur(8px);
  opacity: 0.7;
  z-index: -1;
}

#rootContainer {
  height: 100vh;
}

/* mobile view settings */
@media only screen and (max-width: 1280px) {
  #root::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("/public/background_mobile.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: blur(8px);
    opacity: 0.5;
    z-index: -1;
  }

}

@media only screen and (max-width: 600px) {
  #root::before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("/public/background_mobile_xs.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    filter: blur(8px);
    opacity: 0.5;
    z-index: -1;
  }
}

nav {
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.auth-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

#logoContainer {
  text-align: center;
  margin-top: 10%;
  margin-bottom: 10%;
}

#logoContainer img {
  width: 70%;
}

#userContainer {
  margin-top: 20%;
  margin-bottom: 20%;
  height: 100%;
  padding-left: 9%;
}

#userContainer img {
  width: 10%;
  height: 100%;
  margin: 0;
  padding: 0;
}

#userContainer span {
  width: 80%;
  display: inline-block;
  font-size: 1.1rem;
  overflow: hidden !important;
  text-overflow: ellipsis;
  font-weight: 400;
  padding-left: 11%;
}

.menu-option {
  text-decoration: none;
  color: #222;
  padding: 0;
}

.menu-option.active {
  background-color: #e9f9fb;
  border-style: solid;
  border-width: 0.5px;
  border-color: #9cdce4;
}

#sideSpacer {
  height: "65vh";
}